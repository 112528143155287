"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignalHelper = exports.EG_THRESHOLD = exports.PLC_THRESHOLD = exports.FX_THRESHOLD = void 0;

var _ = require("lodash");

var constants_1 = require("./constants");

var lodash_1 = require("lodash");

exports.FX_THRESHOLD = 20.0;
exports.PLC_THRESHOLD = 20.0;
exports.EG_THRESHOLD = 20.0;
exports.SignalHelper = {
  isValidFX: function isValidFX(rate) {
    return rate >= exports.FX_THRESHOLD;
  },
  isValidPLC: function isValidPLC(rate) {
    return rate >= exports.PLC_THRESHOLD;
  },
  isValidEG: function isValidEG(rate) {
    return rate >= exports.EG_THRESHOLD;
  },
  shouldShowLongShortFXColor: function shouldShowLongShortFXColor(frameSummary, coinCount) {
    if (coinCount === 0) {
      return false;
    }

    var avgFxsummaryLong = exports.SignalHelper.getAvgFxLong(frameSummary);
    var avgFxsummaryShort = exports.SignalHelper.getAvgFxShort(frameSummary);
    var fxLongRate = exports.SignalHelper.getFxLongRate(frameSummary, coinCount);
    var fxShortRate = exports.SignalHelper.getFxShortRate(frameSummary, coinCount);
    var fxLongCount = exports.SignalHelper.getFxCountLong(frameSummary);
    var fxShortCount = exports.SignalHelper.getFxCountShort(frameSummary);
    var isValidFxLong = exports.SignalHelper.isValidFX(fxLongRate);
    var isValidFxShort = exports.SignalHelper.isValidFX(fxShortRate);
    var isLongColor = isValidFxLong && (!isValidFxShort || isValidFxShort && fxLongCount > fxShortCount) && (avgFxsummaryLong || 0) >= 30;
    var isShortColor = isValidFxShort && (!isValidFxLong || isValidFxLong && fxShortCount > fxLongCount) && (avgFxsummaryShort || 0) >= 30;
    return {
      isLongColor: isLongColor,
      isShortColor: isShortColor
    };
  },
  shouldShowLongShortPLCColor: function shouldShowLongShortPLCColor(frameSummary, coinCount) {
    if (coinCount === 0) {
      return {
        isLongPLCColor: false,
        isShortPLCColor: false
      };
    }

    var plcLongRate = exports.SignalHelper.getPLCLongRate(frameSummary, coinCount);
    var pclShortRate = exports.SignalHelper.getPLCShortRate(frameSummary, coinCount);
    var isValidPLCLong = exports.SignalHelper.isValidPLC(plcLongRate);
    var isValidPLCShort = exports.SignalHelper.isValidPLC(pclShortRate);
    var isLongPLCColor = isValidPLCLong;
    var isShortPLCColor = isValidPLCShort;
    return {
      isLongPLCColor: isLongPLCColor,
      isShortPLCColor: isShortPLCColor
    };
  },
  shouldShowLongShortEGColor: function shouldShowLongShortEGColor(frameSummary, coinCount) {
    if (coinCount === 0) {
      return {
        isLongPLCColor: false,
        isShortPLCColor: false
      };
    }

    var egLongRate = exports.SignalHelper.getEGLongRate(frameSummary, coinCount);
    var egShortRate = exports.SignalHelper.getEGShortRate(frameSummary, coinCount);
    var isValidEGLong = exports.SignalHelper.isValidEG(egLongRate);
    var isValidEGShort = exports.SignalHelper.isValidEG(egShortRate);
    return {
      isLongEGColor: isValidEGLong,
      isShortEGColor: isValidEGShort
    };
  },
  getFxCountByTrend: function getFxCountByTrend(frameSummary, trendType) {
    return _.get(frameSummary, "[".concat(trendType, "].fxCount"), 0);
  },
  getFxCountLong: function getFxCountLong(frameSummary) {
    return exports.SignalHelper.getFxCountByTrend(frameSummary, constants_1.TrendType.Down);
  },
  getFxCountShort: function getFxCountShort(frameSummary) {
    return exports.SignalHelper.getFxCountByTrend(frameSummary, constants_1.TrendType.Up);
  },
  getSummaryByTrend: function getSummaryByTrend(frameSummary, trendType) {
    return _.get(frameSummary, "[".concat(trendType, "].summary"), "--");
  },
  getSummaryLong: function getSummaryLong(frameSummary) {
    return exports.SignalHelper.getSummaryByTrend(frameSummary, constants_1.TrendType.Down);
  },
  getSummaryShort: function getSummaryShort(frameSummary) {
    return exports.SignalHelper.getSummaryByTrend(frameSummary, constants_1.TrendType.Up);
  },
  getAvgFxLong: function getAvgFxLong(frameSummary) {
    return exports.SignalHelper.getAvgFxByTrend(frameSummary, constants_1.TrendType.Down);
  },
  getAvgFxShort: function getAvgFxShort(frameSummary) {
    return exports.SignalHelper.getAvgFxByTrend(frameSummary, constants_1.TrendType.Up);
  },
  getAvgFxByTrend: function getAvgFxByTrend(frameSummary, trendType) {
    var avg = _.get(frameSummary, "[".concat(trendType, "].avgFx"));

    if (avg == undefined) return undefined;
    return Math.round(avg * 10) / 10;
  },
  getPLCCountLong: function getPLCCountLong(frameSummary) {
    return exports.SignalHelper.getPLCCountByTrend(frameSummary, constants_1.TrendType.Down);
  },
  getPLCCountShort: function getPLCCountShort(frameSummary) {
    return exports.SignalHelper.getPLCCountByTrend(frameSummary, constants_1.TrendType.Up);
  },
  getEGCountLong: function getEGCountLong(frameSummary) {
    return exports.SignalHelper.getEGCountByTrend(frameSummary, constants_1.TrendType.Down);
  },
  getEGCountShort: function getEGCountShort(frameSummary) {
    return exports.SignalHelper.getEGCountByTrend(frameSummary, constants_1.TrendType.Up);
  },
  getPLCCountByTrend: function getPLCCountByTrend(frameSummary, trendType) {
    return _.get(frameSummary, "[".concat(trendType, "].plcCount")) || 0;
  },
  getEGCountByTrend: function getEGCountByTrend(frameSummary, trendType) {
    return _.get(frameSummary, "[".concat(trendType, "].egCount")) || 0;
  },
  getCoinCount: function getCoinCount(summary) {
    return _.get(summary, "coinCount", 0);
  },
  getFxLongRate: function getFxLongRate(frameSummary, coinCount) {
    var fxCount = exports.SignalHelper.getFxCountLong(frameSummary);
    if (coinCount === 0) return 0;
    return (0, lodash_1.round)(fxCount / coinCount * 10000) / 100;
  },
  getFxShortRate: function getFxShortRate(frameSummary, coinCount) {
    var fxCount = exports.SignalHelper.getFxCountShort(frameSummary);
    if (coinCount === 0) return 0;
    return (0, lodash_1.round)(fxCount / coinCount * 10000) / 100;
  },
  getPLCLongRate: function getPLCLongRate(frameSummary, coinCount) {
    var plcCount = exports.SignalHelper.getPLCCountLong(frameSummary);
    if (coinCount === 0) return 0;
    return (0, lodash_1.round)(plcCount / coinCount * 10000) / 100;
  },
  getPLCShortRate: function getPLCShortRate(frameSummary, coinCount) {
    var plcCount = exports.SignalHelper.getPLCCountShort(frameSummary);
    if (coinCount === 0) return 0;
    return (0, lodash_1.round)(plcCount / coinCount * 10000) / 100;
  },
  getEGLongRate: function getEGLongRate(frameSummary, coinCount) {
    var egCount = exports.SignalHelper.getEGCountLong(frameSummary);
    if (coinCount === 0) return 0;
    return (0, lodash_1.round)(egCount / coinCount * 10000) / 100;
  },
  getEGShortRate: function getEGShortRate(frameSummary, coinCount) {
    var egCount = exports.SignalHelper.getEGCountShort(frameSummary);
    if (coinCount === 0) return 0;
    return (0, lodash_1.round)(egCount / coinCount * 10000) / 100;
  }
};