import * as _ from 'lodash';
import {
  RequestQueryBuilder,
  CondOperator,
  QueryFilter,
  QuerySort,
} from '@nestjsx/crud-request';
import useFetch from 'hooks/useFetch';
import { requestServices } from 'services';

export interface IUseGetListOptions {
  filters?: Array<QueryFilter>;
  sorts?: Array<QuerySort>;
  page?: number;
  limit?: number;
  joins?: string[];
}

export const useGetList = (resource: string, options: IUseGetListOptions) => {
  const url = `/${resource}`;
  const query = RequestQueryBuilder.create({
    filter: _.get(options, 'filters', []),
    sort: _.get(options, 'sorts', []),
    limit: options.limit || 25,
    page: options.page || 0,
    join: _.get(options, 'joins', [] as string[]).map((join: string) => ({
      field: join,
    })),
  }).query(true);
  return useFetch(
    () => {
      return requestServices.crudClient.get(url + '?' + query);
    },
    [JSON.stringify(options)],
    {
      defaultValue: {},
    },
  );
};
