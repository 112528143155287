"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNewUser = void 0;

var moment = require("moment");

var isNewUser = function isNewUser(user) {
  if (!user || !user.verifiedAt) return false;
  var verifyAt = moment(user.verifiedAt);
  var now = moment();
  var diffNow = now.diff(verifyAt, "seconds") / (60 * 24 * 60);
  return diffNow <= 30 && user.orderCount === 0;
};

exports.isNewUser = isNewUser;