import * as _ from 'lodash';
import { PaginationConfig } from 'antd/lib/pagination';
import moment from 'moment';
import slugify from 'slugify';

const generateSlug = (link: string, id: number) => {
  return slugify(`${link}-i${id}`, {
    replacement: '-',
    lower: true,
    trim: true,
    locale: 'vi',
    remove: /[,;<>#$%^&?*+~.()'"!:@]/g,
  });
};

const generateSimpleSlug = (link: string) => {
  return slugify(`${link}`, {
    replacement: '-',
    lower: true,
    trim: true,
    locale: 'vi',
    remove: /[,;<>#$%^&?*+~.()'"!:@]/g,
  });
};

const initPagination: PaginationConfig = {
  current: 1,
  pageSize: 10,
  total: 0,
  // showTotal: (total, range) => (
  //   <Button type='dashed' className='color-darkcyan' title='Total'>
  //     •《 {total} 》•
  //   </Button>
  // ),
  showSizeChanger: true,
  pageSizeOptions: ['5', '10', '20', '50'],
};

const formatWithCurrency = (amount: number, currency: string) => {
  var formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(amount);
};

const formatCurrency = (amount: number) => {
  return roundNumber(amount);
};

const formatDateTable = (day: string) => {
  return moment(day).format('DD/MM/YYYY HH:mm');
};

const formatDateTimeTable = (day: string) => {
  return moment(day).format('DD/MM/YYYY HH:mm:ss');
};

const formatFromUnixSecond = (time: number | undefined) => {
  if (!time) return '';
  return moment(time * 1000).format('DD/MM/YYYY HH:mm');
};

const roundNumber = (num: number) => _.round(num, 2);

export default {
  initPagination,
  formatWithCurrency,
  formatCurrency,
  formatDateTable,
  formatDateTimeTable,
  formatFromUnixSecond,
  roundNumber,
  generateSlug,
  generateSimpleSlug,
};
