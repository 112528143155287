import { RequestQueryBuilder } from '@nestjsx/crud-request';

import useMutate from 'hooks/useMutate';
import { requestServices } from 'services';

export const useUpdateOne = (
  resource: string,
  callback?: (err: any, result: any) => void,
  keyPath?: string,
) => {
  keyPath = keyPath || 'id';
  return useMutate(
    (_form: any) => {
      const url = `/${resource}/${_form[keyPath!]}`;
      return requestServices.crudClient.patch(url, _form);
    },
    [resource],
    {
      callback,
    },
  );
};
