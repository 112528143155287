import { EVENTNAME } from '@app/common';
import { appHelper } from 'helpers';
import React, { useContext, useEffect, useRef } from 'react';
import { useGlobal } from 'reactn';
import { userServices } from 'services';
import socketIOClient, { Socket } from 'socket.io-client';

export const socket = socketIOClient(
  (window as any).__ENV__.REACT_APP_BASE_SOCKET_URL,
  {
    transports: ['websocket', 'polling'],
    auth: { token: userServices.getAccessToken() },
  },
);

socket.on('connect', () => {
  socket.on(EVENTNAME.client.TOKEN_REVOKE, () => {
    userServices.logout();
  });
});

socket.on('disconnect', () => {
  console.log(socket.id); // undefined
});

export const SocketContext = React.createContext(socket);

export const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};
