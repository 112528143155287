import humps from 'humps';
import { useEffect, useReducer, useState } from 'react';
import { has } from 'helpers/lodash';
import { requestServices } from 'services';

export interface StateT<T> {
  loading: boolean;
  error: Error | null;
  data: T | null;
  fetched: boolean;
}

export interface Action {
  type: string;
  payload?: any;
}

const initState = (_default: any): StateT<any> => ({
  loading: false,
  error: null,
  data: _default,
  fetched: false,
});

const reducer = (state: StateT<any>, action: Action) => {
  switch (action.type) {
    case 'startFetch':
      return { ...state, loading: true };
    case 'fetchSuccess':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        fetched: true,
      };
    case 'fetchError':
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
        fetched: true,
      };
    default:
      return state;
  }
};

export const useFetch = (
  promise: any,
  when: any[] = [],
  options: { defaultValue: any } = { defaultValue: null },
) => {
  const [{ data, error, loading, fetched }, dispatch] = useReducer(
    reducer,
    initState(options.defaultValue),
  );
  let mount = true;
  useEffect(() => {
    mount = true;
    return () => {
      mount = false;
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'startFetch' });
      try {
        const { data: _data } = await promise();
        if (has(_data, 'statusCode') && has(_data, 'data')) {
          if (!mount) {
            return;
          }
          dispatch({
            type: 'fetchSuccess',
            payload: _data.data,
          });
          return;
        }
        if (!mount) {
          return;
        }
        dispatch({ type: 'fetchSuccess', payload: _data });
      } catch (err) {
        if (!mount) {
          return;
        }
        dispatch({ type: 'fetchError', payload: err });
      }
    };
    fetchData();
  }, when);
  return { error, data, loading, fetched };
};

export const useFetchAPI = (
  resource: string,
  params: any,
  when: any[] = [],
) => {
  return useFetch(() => {
    return requestServices.userClient.get(`/${resource}`, { params });
  }, when);
};

export default useFetch;
