import header from './header.json';
import button from './button.json';
import error from './error.json';
import action from './action.json';
import message from './message.json';
import nav from './nav.json';
import tab from './tab.json';
import field from './field.json';
import other from './other.json';
import frame from './frame.json';

export default {
  name: 'English',
  icon: 'flag-icon flag-icon-gb',
  translations: {
    ...header,
    ...button,
    ...error,
    ...action,
    ...message,
    ...nav,
    ...tab,
    ...field,
    ...other,
    ...frame,
  },
};
