import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface PropsT extends RouteProps {
  component: any;
  name?: string;
}

export default (props: PropsT) => {
  const { component: Component, ...rest } = props;

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};
