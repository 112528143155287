import i18n from 'i18next';
import { regions } from 'localization';
import { initReactI18next } from 'react-i18next';
import { localizationHelper } from './helpers';

i18n
  // need to testing can useTranslate function
  .use(initReactI18next)
  // initial translate object
  .init({
    // init with resources
    resources: regions,
    lng: localizationHelper.getCurrentLanguage(),
    fallbackLng: regions.en.key,
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
