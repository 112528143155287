import vi from './vi';
import en from './en';
import requestLocalizations from './requestLocalizations';
import { Locale } from 'antd/lib/locale-provider';
import { localStorageConstants } from 'constants/index';
// Flags
import enFlag from 'assets/images/flags/en.svg';
import enUS from 'antd/lib/locale/en_US';

import viFlag from 'assets/images/flags/vi.svg';
import viUS from 'antd/lib/locale/vi_VN';

const regions = {
  en: {
    key: 'en',
    name: en.name,
    flag: enFlag,
    antdLocale: enUS,
    translations: en.translations,
  },
  vi: {
    key: 'vi',
    name: vi.name,
    flag: viFlag,
    antdLocale: viUS,
    translations: vi.translations,
  },
};

export interface IRegionItem {
  key: string;
  name: string;
  flag: string;
  antdLocale: Locale;
}

const getCurrentLanguage = () => {
  // return regions.vi.key;
  return (
    localStorage.getItem(localStorageConstants.KEY_LANGUAGE) || regions.vi.key
  );
};

const changeLanguage = (language: string) => {
  if (language === getCurrentLanguage()) return;
  localStorage.setItem(localStorageConstants.KEY_LANGUAGE, language);
  window.location.reload();
};

export { regions, requestLocalizations, changeLanguage, getCurrentLanguage };
