import { RequestQueryBuilder } from '@nestjsx/crud-request';
import useFetch from 'hooks/useFetch';
import { useCallback, useState } from 'reactn';
import { requestServices } from 'services';

export const useGetOne = (
  resource: string,
  id: number | string,
  joins: string[] = [],
) => {
  const [count, setCount] = useState(0);
  const url = `/${resource}/${id}`;
  const query = RequestQueryBuilder.create({
    join: (joins || []).map((join: string) => ({
      field: join,
    })),
  }).query(true);
  const refresh = useCallback(() => [setCount(s => s + 1)], [setCount]);
  const rest = useFetch(
    () => {
      return requestServices.crudClient.get(url + '?' + query);
    },
    [resource, id, count],
    {
      defaultValue: null,
    },
  );

  return { ...rest, refresh };
};
