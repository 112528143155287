import * as _ from 'lodash';
import { QuerySort, QueryFilter } from '@nestjsx/crud-request';
import { SorterResult } from 'antd/lib/table/interface';
import { useCallback } from 'react';
import { useEffect, useState } from 'reactn';
import { IUseGetListOptions, useGetList } from './useGetList';

const initState: IUseGetListOptions = {
  filters: [],
  sorts: [],
  page: 1,
  limit: 25,
};

interface IReload {
  count: number;
}

const mappingOrderValue = (order: string) => {
  if (order === 'ascend') return 'ASC';
  if (order === 'descend') return 'DESC';
  return undefined;
};

const convertSortAntdToNest = (
  sorter?: SorterResult<any> | SorterResult<any>[],
) => {
  let sorts: Array<QuerySort> = [];
  if (sorter && !_.isArray(sorter)) {
    const order = mappingOrderValue((sorter as SorterResult<any>).order as any);
    if (!order) return undefined;
    sorts = [
      {
        field: (sorter as SorterResult<any>).field as any,
        order,
      },
    ];
  } else if (sorter && _.isArray(sorter)) {
    sorts = (sorter as SorterResult<any>[]).reduce(
      (prev: Array<QuerySort>, st) => {
        const order = mappingOrderValue(st.order as any);
        if (!order) return prev;
        prev.push({
          field: st.field as any,
          order,
        });
        return prev;
      },
      [] as Array<QuerySort>,
    );
  }

  return sorts;
};

export interface IHookTableResult {
  error: string;
  data?: { data: any[]; total: number };
  loading: boolean;
  changeFilters: (filters: Array<QueryFilter>) => void;
  changeSorter: (sorter?: SorterResult<any> | SorterResult<any>[]) => void;
  changePagination: (pagination: {
    current: number;
    pageSize: number | undefined;
  }) => void;
  resource: string;
  reload: () => void;
  state: IReload & IUseGetListOptions;
}

export const useTable = (resource: string, iState?: IUseGetListOptions) => {
  const initialState: IReload & IUseGetListOptions = {
    ...initState,
    ...(iState || {}),
    count: 0,
  };
  const [state, setState] = useState<IReload & IUseGetListOptions>(
    initialState,
  );

  const result = useGetList(resource, state);
  const reload = useCallback(() => {
    setState((state) => ({ ...state, count: state.count + 1 }));
  }, []);
  const changePagination = useCallback(
    (pagination: { current: number; pageSize: number | undefined }) => {
      const page = pagination.current;
      const limit = pagination.pageSize || 25;

      setState((prev) => ({ ...prev, page, limit }));
    },
    [setState],
  );

  const changeSorter = useCallback(
    (sorter?: SorterResult<any> | SorterResult<any>[]) => {
      setState((prev) => ({ ...prev, sorts: convertSortAntdToNest(sorter) }));
    },
    [setState],
  );

  const changeFilters = useCallback(
    (filters: Array<QueryFilter>) => {
      setState((prev) => ({
        ...prev,
        filters,
        sorts: prev.sorts,
        limit: prev.limit,
        page: 1,
      }));
    },
    [setState],
  );
  return {
    ...result,
    data: {
      ...result.data,
      data: _.map(result.data.data, (item, idx) => ({
        ...item,
        idIndex: idx + 1 + ((state.page || 1) - 1) * (state.limit || 1),
      })),
    },
    changePagination,
    changeSorter,
    changeFilters,
    reload,
    state,
    resource,
  } as IHookTableResult;
};
