export default {
  KEY_CURRENT_PERMISSIONS: 'currentPermissions' as string,
  KEY_CURRENT_USER: 'currentUser' as string,

  KEY_COMMISSION_CFG: 'cfgs',

  api: {
    USER_DETAIL: '/me' as string,
  },
};
