import { authConstants, localStorageConstants } from 'constants/index';
import { useEffect } from 'react';
import { useGlobal, useState } from 'reactn';
import { requestServices, userServices } from 'services';

export const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useGlobal<any>(
    authConstants.KEY_CURRENT_USER,
  );
  const [loading, setLoading] = useState(true);
  const [currentPermissions, setCurrentPermissions] = useGlobal<any>(
    authConstants.KEY_CURRENT_PERMISSIONS,
  );

  useEffect(() => {
    if (userServices.getAccessToken() && !currentUser) {
      setLoading(true);
      userServices
        .getCurrentUserInfo()
        .then((userData) => {
          setCurrentUser(userData.data, () => {
            setCurrentPermissions(userData.data.permissions, () => {
              requestServices.walletClient
                .request({
                  url: `/networks`,
                })
                .then((res) => {
                  let respNetworks = res.data.data;
                  if (respNetworks.length > 0) {
                    const selectedNetworkId = respNetworks[0]['id'];
                    requestServices.walletClient
                      .request({
                        url: `/info`,
                        params: {
                          network_id: selectedNetworkId,
                        },
                      })
                      .then((res) => {
                        console.log('Init address success!');
                      });
                  }
                });
              setLoading(() => false);
            });
          });
        })
        .catch(() => {
          setLoading(() => false);
        });
    } else {
      setLoading(false);
    }
  }, [currentUser, setCurrentUser]);

  if (currentUser && userServices.getCurrentUserId() !== currentUser.id) {
    localStorage.setItem(
      localStorageConstants.KEY_CURRENT_USER_ID,
      currentUser.id,
    );
  }

  return {
    currentPermissions,
    currentUser,
    setCurrentUser,
    loading,
  };
};

export default {
  useUserInfo,
};
