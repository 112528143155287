import { cookieHelper } from 'helpers';
import { localStorageConstants, authConstants } from 'constants/index';
import { requestServices } from 'services';
import { browserHistory } from 'helpers';

const logout = () => {
  clearAccessToken();
  window.location.href = `/login`;
};

const denyAccess = () => {
  browserHistory.push('/403');
};

const getAccessToken = () =>
  localStorage.getItem(localStorageConstants.ACCESS_TOKEN);

const clearAccessToken = () => {
  localStorage.removeItem(localStorageConstants.ACCESS_TOKEN);
};

const setAccessToken = (value: string) =>
  localStorage.setItem(localStorageConstants.ACCESS_TOKEN, value);

const getCurrentUserInfo = () => {
  return requestServices.userClient
    .get(authConstants.api.USER_DETAIL)
    .then((response) => response.data);
};

const getCurrentUserId = () => {
  const userId = localStorage.getItem(
    localStorageConstants.KEY_CURRENT_USER_ID,
  );

  return userId;
};

export default {
  logout,
  denyAccess,
  getAccessToken,
  getCurrentUserInfo,
  getCurrentUserId,
  setAccessToken,
  clearAccessToken,
};
