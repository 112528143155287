import useMutate from 'hooks/useMutate';
import { requestServices } from 'services';

export const useDeleteOne = (
  resource: string,
  callback?: (err: any, result: any) => void,
) => {
  return useMutate(
    (id: number) => {
      const url = `/${resource}/${id}`;
      return requestServices.crudClient.delete(url);
    },
    [resource],
    {
      callback,
    },
  );
};
