"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StockType = exports.OrderStatus = exports.TrendType = exports.EVENTNAME = exports.FRAMES = exports.ErrorCode = exports.TransferType = exports.Role = exports.QueueProcessorHandler = exports.QueueProcessor = exports.LoginType = exports.CONSTANTS_EXAMPLE = void 0;
exports.CONSTANTS_EXAMPLE = "example";
var LoginType;

(function (LoginType) {
  LoginType["BASIC"] = "basic";
  LoginType["GOOGLE"] = "google";
  LoginType["FACEBOOK"] = "facebook";
})(LoginType = exports.LoginType || (exports.LoginType = {}));

var QueueProcessor;

(function (QueueProcessor) {
  QueueProcessor["EMAIL"] = "email";
})(QueueProcessor = exports.QueueProcessor || (exports.QueueProcessor = {}));

var QueueProcessorHandler;

(function (QueueProcessorHandler) {
  QueueProcessorHandler["SEND_EMAIL"] = "send-email";
})(QueueProcessorHandler = exports.QueueProcessorHandler || (exports.QueueProcessorHandler = {}));

var Role;

(function (Role) {
  Role["Admin"] = "Admin";
  Role["Monitor"] = "Monitor";
  Role["User"] = "User";
  Role["System"] = "System";
})(Role = exports.Role || (exports.Role = {}));

var TransferType;

(function (TransferType) {
  TransferType["Subtract"] = "subtract";
  TransferType["Addition"] = "addition";
  TransferType["Commission"] = "commission";
})(TransferType = exports.TransferType || (exports.TransferType = {}));

var ErrorCode;

(function (ErrorCode) {
  ErrorCode["WRONG_EMAIL"] = "wrong_email";
  ErrorCode["EMAIL_NOT_FOUND"] = "email_not_found";
  ErrorCode["DUPLICATE_EMAIL"] = "duplicate_email";
  ErrorCode["DUPLICATE_TELEGRAM"] = "duplicate_telegram";
  ErrorCode["TELEGRAM_LINKED"] = "telegram_linked";
  ErrorCode["DUPLICATE_EMAIL_OR_TELEGRAM"] = "duplicate_email_or_telegram";
  ErrorCode["UNKNOWN"] = "unknown";
  ErrorCode["WRONG_FIELD"] = "wrong_field";
  ErrorCode["WRONG_PASSWORD"] = "wrong_password";
  ErrorCode["EMPTY_USERNAME"] = "empty_username";
  ErrorCode["WRONG_USERNAME"] = "wrong_username";
  ErrorCode["WRONG_PLAN"] = "wrong_plan";
  ErrorCode["ACCOUNT_NOT_CREATED"] = "account_not_created";
  ErrorCode["ACCOUNT_NOT_CREATED_OR_EXPIRED"] = "account_not_created_or_expired";
  ErrorCode["ACCOUNT_NOT_VERIFIED"] = "account_not_verified";
  ErrorCode["NOT_INTERACTIVE_WITH_BOT_YET"] = "not_interactive";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));

exports.FRAMES = ["M5", "M15", "M30", "H1", "H4", "H8", "H12", "D1", "D3", "W", "M"];
exports.EVENTNAME = {
  server: {
    JOIN_COIN_ROOM: "join-coin-room",
    JOIN_COIN_POINT_ROOM: "join-coin-point-room",
    JOIN_SUMMARY_STATISTIC_ROOM: "join-summary-statistic-room",
    LEAVE_COIN_ROOM: "leave-coin-room"
  },
  client: {
    COIN_SIGNAL: "coin-signal",
    COIN_BATCH_SIGNAL: "coin-batch-signal",
    COIN_POINT: "coin-point",
    COIN_SUMMARY: "coin-summary",
    COIN_SUMMARY_STATISTIC: "coin-summary-statistic",
    TOKEN_REVOKE: "token-revoke"
  }
};
var TrendType;

(function (TrendType) {
  TrendType["Up"] = "UP";
  TrendType["Down"] = "DOWN";
})(TrendType = exports.TrendType || (exports.TrendType = {}));

var OrderStatus;

(function (OrderStatus) {
  OrderStatus["Created"] = "created";
  OrderStatus["Transfered"] = "transfered";
  OrderStatus["Finished"] = "finished";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));

var StockType;

(function (StockType) {
  StockType["Future"] = "future";
  StockType["Spot"] = "spot";
})(StockType = exports.StockType || (exports.StockType = {}));