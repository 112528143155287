import { regions, requestLocalizations } from 'localization';
import { localStorageConstants } from '../constants';

const localize = (key: string, lang?: string) => {
  const language = lang || regions.vi.key;
  const localization = requestLocalizations[key];
  if (localization && localization[language]) {
    return localization[language];
  }
  return key;
};

const getCurrentLanguage = () => {
  // return regions.vi.key;
  let language =
    localStorage.getItem(localStorageConstants.KEY_LANGUAGE) || regions.vi.key;

  // check if language in localstorage exist in project or not
  if (!regions[language]) language = regions.en.key;

  return language;
};

const changeLanguage = (newLanguage: string) => {
  localStorage.setItem(localStorageConstants.KEY_LANGUAGE, newLanguage);
  window.location.reload();
};

export default {
  localize,
  getCurrentLanguage,
  changeLanguage,
};
