import axios, { AxiosError } from 'axios/index';
import { BASE_URL } from 'configs';
import { requestHelper } from 'helpers';
import { userServices } from 'services';

export const BASE_API_URL: string = `${BASE_URL}/api`;
export const BASE_CRUD_URL: string = `${BASE_URL}/crud`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const getAuthorization = () => `Bearer ${userServices.getAccessToken()}`;

const userClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Authorization: getAuthorization(),
  },
  withCredentials: false,
});

userClient.interceptors.response.use(
  (response) => response,
  (error) => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const crudClient = axios.create({
  baseURL: BASE_CRUD_URL,
  headers: {
    Authorization: getAuthorization(),
  },
  withCredentials: false,
});

crudClient.interceptors.response.use(
  (response) => response,
  (error) => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const walletClient = axios.create({
  baseURL: `${BASE_URL}/api/wallet`,
  headers: {
    Authorization: getAuthorization(),
  },
  withCredentials: false,
});

walletClient.interceptors.response.use(
  (response) => response,
  (error) => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const requestErrorHandler = (
  error: AxiosError | null,
  cb?: (data: any, cfg: any) => void,
) => {
  if (!error) return;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    cb?.(error.response.data, error.config);
    return;
  }
  cb?.(null, error.config);
  // if (error.request) {
  //   // The request was made but no response was received
  //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   // http.ClientRequest in node.js
  //   console.log(error.request);
  // }
  // {
  //   // Something happened in setting up the request that triggered an Error
  //   console.log('Error', error.message);
  // }
  // console.log(error.config);
};

// @ts-ignore
export default {
  userClient,
  crudClient,
  walletClient,
  errorHandler: requestErrorHandler,
};
