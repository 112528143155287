import LoadingPage from 'components/LoadingPage';
import 'configs';
import { browserHistory } from 'helpers';
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Router, Switch } from 'react-router-dom';
import './app.less';
import PrivateRoute from './components/PrivateRoute';

const loading = () => <LoadingPage />;

// Containers
const AdminLayout = Loadable({
  loader: () => import('./layouts/AdminLayout'),
  loading,
});

const UserAssetLayout = Loadable({
  loader: () => import('./layouts/UserAssetLayout'),
  loading,
});

// Containers
const UserLayout = Loadable({
  loader: () => import('./layouts/UserLayout'),
  loading,
});

// Containers
const LandingLayout = Loadable({
  loader: () => import('./layouts/LandingLayout'),
  loading,
});

// Containers
const NewsLayout = Loadable({
  loader: () => import('./layouts/NewsLayout'),
  loading,
});

// pages
const Login = Loadable({
  loader: () => import('./screens/Login'),
  loading,
});

const RenewPassword = Loadable({
  loader: () => import('./screens/RenewPassword'),
  loading,
});

const ForgotPassword = Loadable({
  loader: () => import('./screens/ForgotPassword'),
  loading,
});

const Register = Loadable({
  loader: () => import('./screens/Register'),
  loading,
});

const VerifyRegister = Loadable({
  loader: () => import('./screens/VerifyRegister'),
  loading,
});

const Page404 = Loadable({
  loader: () => import('./screens/Page404'),
  loading,
});

const Page403 = Loadable({
  loader: () => import('./screens/Page403'),
  loading,
});

const Page500 = Loadable({
  loader: () => import('./screens/Page500'),
  loading,
});

function App() {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/login" component={UserLayout} />
        <Route exact path="/renewpassword" component={RenewPassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/register" component={UserLayout} />
        <Route exact path="/verify" component={VerifyRegister} />
        <Route exact path="/404" component={Page404} />
        <Route exact path="/403" component={Page403} />
        <Route exact path="/500" component={Page500} />
        <PrivateRoute path="/admin" name="Admin" component={AdminLayout} />
        <PrivateRoute
          path="/assets"
          name="Assets"
          component={UserAssetLayout}
        />
        <PrivateRoute path="/new" name="New" component={NewsLayout} />
        <PrivateRoute path="/posts" name="Post" component={NewsLayout} />
        <PrivateRoute path="/orders" name="New" component={UserLayout} />
        <PrivateRoute path="/" name="Landing" component={LandingLayout} />
      </Switch>
    </Router>
  );
}

export default App;
