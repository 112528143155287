import humps from 'humps';
import { useCallback, useEffect, useReducer } from 'react';
import { has } from 'helpers/lodash';
import { requestServices } from 'services';
import { AxiosError, Method } from 'axios';

interface StateT<T> {
  loading: boolean;
  error: Error | null;
  data: T | null;
}

interface Action {
  type: string;
  payload?: any;
}

const initState = (_default: any): StateT<any> => ({
  loading: false,
  error: null,
  data: _default,
});

const reducer = (state: StateT<any>, action: Action) => {
  switch (action.type) {
    case 'startFetch':
      return { ...state, loading: true };
    case 'fetchSuccess':
      return { ...state, loading: false, data: action.payload };
    case 'fetchError':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const useMutate = (
  promise: any,
  when: any[] = [],
  options: {
    valueDefault?: any;
    inputIsSnake?: boolean;
    callback?: (err: any, data: any, form?: any) => void;
  } = {},
): [StateT<any>, (formData: any, cb?: any) => void] => {
  const {
    valueDefault = [],
    inputIsSnake = false,
    callback = (err = null, data: any = null) => { },
  } = options;

  const [{ data, error, loading }, dispatch] = useReducer(
    reducer,
    initState(valueDefault),
  );

  let mount = true;
  useEffect(() => {
    mount = true;
    return () => {
      mount = false;
    };
  }, []);

  const mutate = useCallback(
    (formData, inlineCallBack = () => { }) => {
      const _form = inputIsSnake ? humps.decamelizeKeys(formData) : formData;
      const fetchData = async () => {
        dispatch({ type: 'startFetch' });

        try {
          const { data: _data } = await promise(_form);

          if (has(_data, 'data')) {
            if (!mount) {
              return;
            }
            const tranform = humps.camelizeKeys(_data.data);
            dispatch({
              type: 'fetchSuccess',
              payload: tranform,
            });
            callback(null, tranform, _form);
            inlineCallBack(null, tranform);
            return;
          }
          const tranform = humps.camelizeKeys(_data);

          if (!mount) {
            return;
          }
          callback(null, tranform, _form);
          inlineCallBack(null, tranform);
          dispatch({ type: 'fetchSuccess', payload: tranform });
        } catch (err) {
          if (!mount) {
            return;
          }

          dispatch({ type: 'fetchError', payload: err });
          callback(err, null);
          inlineCallBack(err, null);
        }
      };
      fetchData();
    },
    [...when, callback],
  );

  return [{ error, data, loading }, mutate];
};

export const useMutateAPI = (
  resource: string,
  when: any[] = [],
  callback = (err: AxiosError, data: any, form?: any) => { },
) => {
  return useMutate(
    (formData: any) => {
      return requestServices.userClient.post(`/${resource}`, formData);
    },
    when,
    { valueDefault: null, callback },
  );
};

export const useMutateCrud = (
  resource: string,
  when: any[] = [],
  callback = (err: Error, data: any) => { },
  method: Method = 'POST',
) => {
  return useMutate(
    (formData: any) => {
      return requestServices.crudClient.request({
        url: `/${resource}`,
        data: formData,
        method,
      });
    },
    when,
    { valueDefault: null, callback },
  );
};

export default useMutate;
